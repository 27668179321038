// Dependencies
import React from "react"

const Markdown = ({ className, field }) =>
  field && (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: field.childMarkdownRemark.html }}
    />
  )

export default Markdown
