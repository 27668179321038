// Dependencies
import React, { useRef, useEffect, useState } from "react"
// import { graphql, useStaticQuery } from 'gatsby'
// import { useSpring, animated } from 'react-spring'
// import { useLocation } from "@reach/router"
// Hooks & Helpers
// import priceLabel from '../helpers/priceLabel'
// import useIdle from '../hooks/useIdle'
import useLineItem from "../hooks/useLineItem"
// import useVariantInfo from '../hooks/useVariantInfo'
// Components
import Link from "../components/Link"
// import Markdown from '../components/Markdown'
// import MenuProductInfo from '../components/MenuProductInfo'
// Wrappers
import { useStoreContext } from "../wrappers/Store"

// const MenuCartLineItemOld = (props) => {
//   // Props
//   const { quantity } = props

//   // Content
//   const { title, colourMaterial, price, size } = useLineItem(props)

//   return (
//     <li className="menu__item">
//       <div className="menu__item__label no-hover">
//         <div>
//           <p className="p1">{title}</p>
//           <p className="p3 outer">
//             {/* {colourMaterial} <br /> */}
//             Size: {size} <br />
//             {quantity} ✕ {price}
//           </p>
//         </div>
//       </div>
//     </li>
//   )
// }

// const MenuCartOld = (props) => {
//   const { checkout } = useStoreContext()

//   return (
//     <li className={`menu__item`}>
//       <Link to={`/cart`} className="menu__item__label">
//         {checkout ? (
//           <div className="label">{`Cart [${checkout.lineItems.length}]`}</div>
//         ) : (
//           <div className="label">{`Cart [–]`}</div>
//         )}
//         <div className={`line`} />
//         {/* {(links || currentProduct) && <div className={`line ${willShowLine ? 'show' : ''} ${preventHover ? 'no-hover' : ''}`} />} */}
//       </Link>

//       {checkout?.lineItems.length > 0 && (
//         <div className={`menu__item__branch menu__cart`}>
//           <ul className="branch__inner prel">
//             {checkout.lineItems.map((item, index) => (
//               <MenuCartLineItem {...item} key={`item-${index}`} />
//             ))}

//             <li className="menu__item">
//               <Link to={`/cart`} className="btn">
//                 Review & Checkout
//               </Link>
//             </li>
//           </ul>
//         </div>
//       )}
//     </li>
//   )
// }

// const MenuCartNew = (props) => {
//   const { checkout } = useStoreContext()

//   const willShowBranch = true

//   const handleLinkClick = () => false

//   return (
//     <div className={`menu__branch prel`}>
//       <div
//         className={`menu__link ${willShowBranch ? "active" : ""}`}
//         onClick={handleLinkClick}
//       >
//         <div className="label">
//           {checkout ? `Cart [${checkout.lineItems.length}]` : `Cart [–]`}
//         </div>
//         <div className={`line`} />
//       </div>

//       {checkout?.lineItems.length > 0 && (
//         <div
//           className={`menu__branches branch-wrapper ${
//             willShowBranch ? "active" : ""
//           }`}
//         >
//           {checkout.lineItems.map((item, index) => (
//             <MenuCartLineItem {...item} key={`item-${index}`} />
//           ))}

//           <Link to={`/cart`} className="btn">
//             Review & Checkout
//           </Link>
//         </div>
//       )}
//     </div>
//   )
// }

export const MenuCartLink = ({ handleMenuFocus }) => {
  const { cart } = useStoreContext()

  const handleClick = () => {
    handleMenuFocus(false)
  }

  return (
    <Link to={`/cart`} className={`menu__link`} onClick={handleClick}>
      <div className="label">
        {cart ? `Cart [${cart.totalQuantity}]` : `Cart [–]`}
      </div>
      <div className={`line`} />
    </Link>
  )
}

// const MenuCartLineItem = (props) => {

// 	// Props
// 	const { quantity } = props

// 	// Content
// 	const { title, colourMaterial, price, size } = useLineItem(props)

// 	return (
// 		<div className="menu__branch">
// 			<div className="menu__link">
// 				<div>
// 					<p className="p1">
// 						{title}
// 					</p>
// 					<p className="p3 outer">
// 						{/* {colourMaterial} <br /> */}
// 						Size: {size} <br />
// 						{quantity} ✕ {price}
// 					</p>
// 				</div>
// 			</div>
// 		</div>
// 	)
// }

// const MenuCart = (props) => {

// 	const { checkout } = useStoreContext()

// 	const willShowBranch = true

// 	const handleLinkClick = () => false

// 	return (
// 		checkout?.lineItems.length > 0 && (
// 			<div className={`menu__branches menu__cart branch-wrapper ${willShowBranch ? 'active' : ''}`}>
// 				{checkout.lineItems.map((item, index) => (
// 					<MenuCartLineItem {...item} key={`item-${index}`} />
// 				))}

// 				<Link to={`/cart`} className="btn">Review & Checkout</Link>
// 			</div>
// 		)
// 	)
// }

export default MenuCartLink
