import { useState, useEffect, useCallback } from "react"

const useLocalStorageV2 = (key, initialValue = null) => {
  // Check if we're running in the browser
  const isBrowser = typeof window !== "undefined"

  // Initial state for SSR
  const [state, setState] = useState({
    exists: false,
    initialized: false,
    value: initialValue,
    error: null,
  })

  // Get the current state from localStorage
  const getStorageState = useCallback(() => {
    if (!isBrowser) {
      return {
        exists: false,
        initialized: false,
        value: initialValue,
        error: null,
      }
    }

    try {
      // Check if the key exists
      if (!localStorage.hasOwnProperty(key)) {
        return {
          exists: false,
          initialized: true,
          value: initialValue,
          error: null,
        }
      }

      // Get the raw value
      const rawValue = localStorage.getItem(key)

      // Check if the value is null (not initialized)
      if (rawValue === null) {
        return {
          exists: true,
          initialized: true,
          value: null,
          error: null,
        }
      }

      try {
        // Parse the stored value
        const parsedValue = JSON.parse(rawValue)
        return {
          exists: true,
          initialized: true,
          value: parsedValue,
          error: null,
        }
      } catch (e) {
        // Handle case where value isn't valid JSON
        return {
          exists: true,
          initialized: true,
          value: rawValue,
          error: null,
        }
      }
    } catch (e) {
      // Handle case where localStorage isn't available
      return {
        exists: false,
        initialized: true,
        value: initialValue,
        error: "LocalStorage access error: " + e.message,
      }
    }
  }, [isBrowser, key, initialValue])

  // Set a new value in localStorage
  const setValue = useCallback(
    (newValue) => {
      if (!isBrowser) {
        return false
      }

      try {
        const valueToStore =
          typeof newValue === "string" ? newValue : JSON.stringify(newValue)
        localStorage.setItem(key, valueToStore)

        setState({
          exists: true,
          initialized: true,
          value: newValue,
          error: null,
        })

        return true
      } catch (e) {
        setState((prev) => ({
          ...prev,
          error: "LocalStorage set error: " + e.message,
        }))
        return false
      }
    },
    [isBrowser, key]
  )

  // Remove the value from localStorage
  const removeValue = useCallback(() => {
    if (!isBrowser) {
      return false
    }

    try {
      localStorage.removeItem(key)
      setState({
        exists: false,
        initialized: true,
        value: initialValue,
        error: null,
      })
      return true
    } catch (e) {
      setState((prev) => ({
        ...prev,
        error: "LocalStorage remove error: " + e.message,
      }))
      return false
    }
  }, [isBrowser, key, initialValue])

  // Initialize state on mount and when key changes
  useEffect(() => {
    // Only run in browser
    if (isBrowser) {
      setState(getStorageState())
    }
  }, [isBrowser, getStorageState])

  // Listen for storage events (changes from other tabs)
  useEffect(() => {
    // Only add listener in browser
    if (!isBrowser) {
      return
    }

    const handleStorage = (e) => {
      if (e.key === key) {
        setState(getStorageState())
      }
    }

    window.addEventListener("storage", handleStorage)
    return () => window.removeEventListener("storage", handleStorage)
  }, [isBrowser, key, getStorageState])

  return {
    ...state,
    setValue,
    removeValue,
  }
}

export { useLocalStorageV2 }

// Hook
export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window !== "undefined") {
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key)
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue
      } catch (error) {
        // If error also return initialValue
        console.log(error)
        return initialValue
      }
    } else {
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    if (typeof window !== "undefined") {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error)
      }
    }
  }

  return [storedValue, setValue]
}
