import React, { useContext, useState, useEffect } from "react"
import { createStorefrontApiClient } from "@shopify/storefront-api-client"
import {
  GRAPHQL_PRODUCT_FIELDS,
  GRAPHQL_PRODUCT_VARIANTS,
  GRAPHQL_CART_FIELDS,
} from "../queries/shopify"
import { useLocalStorageV2 } from "../hooks/useLocalStorage"
// import {
//   trackProductLead,
//   trackAddToCart,
//   trackInitCheckout,
//   trackBeginCheckout,
// } from "../helpers/customAnalyticsEvents"

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: process.env.GATSBY_SHOPIFY_API_VERSION || "2025-01",
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
})

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  updateLineItem: () => {},
  client,
  checkout: null,
}

const isBrowser = typeof window !== `undefined`
const LOCAL_STORAGE_KEY = `sftm_cart_id`

export const StoreContext = React.createContext(defaultValues)

export function useStoreContext() {
  const storeContext = useContext(StoreContext)
  return storeContext
}

async function fetchProductById(id) {
  if (!id) return null
  const query = `{
    product(id: "${id}") {
      ${GRAPHQL_PRODUCT_FIELDS}
      ${GRAPHQL_PRODUCT_VARIANTS}
    }
  }`
  return await client.request(query)
}

function useCurrentProduct(currentProduct) {
  const [product, set] = useState(currentProduct)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Handle response
    function handleResult(product) {
      set({
        ...currentProduct,
      })
    }

    // Fetch product by ID
    async function fetchProduct() {
      if (!currentProduct?.productId) return

      setLoading(true)
      setError(null)

      try {
        const response = await fetchProductById(currentProduct.productId)
        handleResult(response?.data?.product)
      } catch (err) {
        setError(err)
        console.error("Error fetching product:", err)
      } finally {
        setLoading(false)
      }
    }

    // Init
    fetchProduct()
  }, [currentProduct?.productId]) // Only re-run if productId changes

  return [product, set]
}

async function fetchCartById(id) {
  if (!id) return null

  const query = `
    {
      cart (
        id: "${id}"
      ) {
        ${GRAPHQL_CART_FIELDS}
      }
    }
  `
  const { data, errors } = await client.request(query)

  if (errors) {
    console.error("cart response error", errors)
    return null
  } else {
    return data
  }
}

async function createCart() {
  const operation = `mutation createCart {
    cartCreate {
      cart {
        ${GRAPHQL_CART_FIELDS}
      }
    }
  }`

  const { data, errors } = await client.request(operation)

  if (errors) {
    console.error("createCart error", errors)
    return null
  } else {
    return data.cartCreate.cart
  }
}

async function addLineItem(cartId = "", lines = []) {
  /* Line item example:
    const lineItemExample = {
      merchandiseId: variantId,
      quantity: 1,
      attributes: [
        key: "gift-wrap",
        value: "yes"
      ]
    }
  */
  const operation = `
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          ${GRAPHQL_CART_FIELDS}
        }
        userErrors {
          field
          message
        }
      }
    }
  `
  const { data, errors } = await client.request(operation, {
    variables: {
      lines: lines,
      cartId: cartId,
    },
  })

  if (errors) {
    console.error("addLineItem error", errors)
    return null
  } else {
    return data.cartLinesAdd
  }
}

async function updateCartLineItem(cartId = "", lines = []) {
  /**
   * Line item example:
   * const lineItemExample = {
   *  id: lineItemId,
   *  quantity: 0
   * }
   */

  const operation = `
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          ${GRAPHQL_CART_FIELDS}
        }
        userErrors {
          field
          message
        }
      }
    }
  `
  const { data, errors } = await client.request(operation, {
    variables: {
      cartId: cartId,
      lines: lines,
    },
  })

  if (errors) {
    console.error("cartLinesUpdate error", errors)
    return null
  } else {
    return data.cartLinesUpdate
  }
}

const StoreProvider = ({ product, children }) => {
  const localStorageCart = useLocalStorageV2(LOCAL_STORAGE_KEY, null)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  // Current product store
  const [currentProduct, setCurrentProduct] = useCurrentProduct(product)

  // -------------------------
  // V2 cart
  const cartInitialized = localStorageCart?.initialized
  const cartId = localStorageCart?.value
  const [cart, setCart] = useState(null)
  // const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Handle response
    function handleExisitingCart(cart) {
      setCart(cart)
    }

    function handleNewCart(cart) {
      // console.log("New cart created:", cart)
      localStorageCart.setValue(cart.id)
      setCart(cart)
    }

    // Fetch product by ID
    async function updateCart() {
      if (cartInitialized && !cart) {
        if (cartId) {
          setLoading(true)
          setError(null)

          try {
            const exisitingCart = await fetchCartById(cartId)
            if (exisitingCart) {
              handleExisitingCart(exisitingCart.cart)
            } else {
              const newCart = await createCart()
              if (newCart) {
                handleNewCart(newCart)
              }
            }
          } catch (err) {
            setError(err)
            console.error("Error fetching cart:", err)
          } finally {
            setLoading(false)
          }
        } else {
          // console.warn("No cartId found in localStorage")
          // console.log(localStorageCart)
          try {
            const newCart = await createCart()
            if (newCart) {
              handleNewCart(newCart)
            }
          } catch (err) {
            setError(err)
            console.error("Error creating cart:", err)
          } finally {
            setLoading(false)
          }
        }
      } else {
        // localStorageCart problem?
      }
    }

    // Init
    updateCart()
  }, [cartId, cartInitialized, cart]) // Only re-run if cartId is found/updated

  const addVariantToCart = async (
    variantId,
    quantity,
    customAttributes,
    callback
  ) => {
    setLoading(true)

    // v2
    try {
      if (cart?.id) {
        const lineItem = {
          merchandiseId: variantId,
          quantity: parseInt(quantity, 10),
          attributes: customAttributes,
        }

        const updatedCart = await addLineItem(cart?.id, [lineItem])
        if (updatedCart) {
          if (callback) callback(updatedCart.cart)
          setCart(updatedCart.cart)
          return
        } else {
          console.error("Add to cart error:", updatedCart)
          return
        }
      }
    } catch (error) {
      console.error("Add to cart error:", error)
      return false
    } finally {
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 500)
      return
    }
  }

  const updateLineItem = async (lineItemID, quantity) => {
    setLoading(true)

    try {
      const updateCart = await updateCartLineItem(cartId, [
        { id: lineItemID, quantity: parseInt(quantity, 10) },
      ])
      if (updateCart) {
        setCart(updateCart.cart)
        return
      }
    } catch (error) {
      console.error("updateLineItem error:", error)
      return false
    } finally {
      setLoading(false)
      return
    }
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        updateLineItem,
        loading,
        didJustAddToCart,
        currentProduct,
        setCurrentProduct,
        // V2
        cart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreProvider
